const dev = {
    endpoints: {
        itarXferAdminAuthn: "https://exmzdudgfb.execute-api.us-west-2.amazonaws.com/prd/itarXferAdminAuthn",
        itarXferAdminAuthz: "https://exmzdudgfb.execute-api.us-west-2.amazonaws.com/prd/itarXferAdminAuthz",
        itarXferCreateSecret: "https://dlrxu0cnn7.execute-api.us-gov-west-1.amazonaws.com/prd/itarXferCreateSecret",
        itarXferAuthzSecret: "https://dlrxu0cnn7.execute-api.us-gov-west-1.amazonaws.com/prd/itarXferAuthzSecret",
        itarXferSinglePartUpload: "https://dlrxu0cnn7.execute-api.us-gov-west-1.amazonaws.com/prd/itarXferSinglePartUpload",
        itarXferStartMultipartUpload: "https://dlrxu0cnn7.execute-api.us-gov-west-1.amazonaws.com/prd/itarXferStartMultipartUpload",
        itarXferGetUploadPartUrl: "https://dlrxu0cnn7.execute-api.us-gov-west-1.amazonaws.com/prd/itarXferGetUploadPartUrl",
        itarXferCompleteMultipartUpload: "https://dlrxu0cnn7.execute-api.us-gov-west-1.amazonaws.com/prd/itarXferCompleteMultipartUpload",
        itarXferCreateTinyUrlCode: 'https://dlrxu0cnn7.execute-api.us-gov-west-1.amazonaws.com/prd/itarXferCreateTinyUrlCode',
        itarXferLookupTinyUrlCode: 'https://dlrxu0cnn7.execute-api.us-gov-west-1.amazonaws.com/prd/itarXferLookupTinyUrlCode',
    },
    downloadBaseUrl: 'http://localhost:3000/download'
};

const prd = {
    endpoints: {
        itarXferAdminAuthn: "https://exmzdudgfb.execute-api.us-west-2.amazonaws.com/prd/itarXferAdminAuthn",
        itarXferAdminAuthz: "https://exmzdudgfb.execute-api.us-west-2.amazonaws.com/prd/itarXferAdminAuthz",
        itarXferCreateSecret: "https://dlrxu0cnn7.execute-api.us-gov-west-1.amazonaws.com/prd/itarXferCreateSecret",
        itarXferAuthzSecret: "https://dlrxu0cnn7.execute-api.us-gov-west-1.amazonaws.com/prd/itarXferAuthzSecret",
        itarXferSinglePartUpload: "https://dlrxu0cnn7.execute-api.us-gov-west-1.amazonaws.com/prd/itarXferSinglePartUpload",
        itarXferStartMultipartUpload: "https://dlrxu0cnn7.execute-api.us-gov-west-1.amazonaws.com/prd/itarXferStartMultipartUpload",
        itarXferGetUploadPartUrl: "https://dlrxu0cnn7.execute-api.us-gov-west-1.amazonaws.com/prd/itarXferGetUploadPartUrl",
        itarXferCompleteMultipartUpload: "https://dlrxu0cnn7.execute-api.us-gov-west-1.amazonaws.com/prd/itarXferCompleteMultipartUpload",
        itarXferCreateTinyUrlCode: 'https://dlrxu0cnn7.execute-api.us-gov-west-1.amazonaws.com/prd/itarXferCreateTinyUrlCode',
        itarXferLookupTinyUrlCode: 'https://dlrxu0cnn7.execute-api.us-gov-west-1.amazonaws.com/prd/itarXferLookupTinyUrlCode',
    },
    downloadBaseUrl: 'https://itar.aspera.ascendingnode.tech/download'
};

const configMap = {
    'prd': prd,
    'dev': dev,
};

const config = configMap[process.env.REACT_APP_STAGE];

export const endpoints = config.endpoints;
export const downloadBaseUrl = config.downloadBaseUrl;
